#values-container{
    background-image: url("../../images/helado-bombon-2353891.webp");
}
.values-container{
    height: auto; 
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}
.cap-white{
    background-color: rgba(255, 255, 255, 0.899);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.values-content{
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}
.values-content .titleh2{
    font-size: 50px;
    text-align: center;
}
.grid-values{
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    grid-template-areas: "a b c d e";
    margin: 0 auto;
    padding-top: 10px;
    grid-gap: 2%;
}
.vg1, .vg2, .vg3, .vg4, .vg5{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 40% 70%;
    grid-template-areas: "a""b";
}
.grid-values .images{
    width: 100%;
    text-align: center;
}
.grid-values .images img{
    width: 60%;
}
.grid-values .descripcion h2{
    font-size: 25px;
    text-align: center;
}
.grid-values .descripcion p{
    font-size: 18px;
    text-align: center;
    color: rgb(54, 54, 54);
}

@media (max-width: 480px){

    .grid-values{
        grid-template-columns: 100%;
        grid-template-areas: "a""b""c""d""e";
        grid-gap: 0%;
    }
    .grid-values .images img{
        width: 60%;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .grid-values .descripcion h2{
        font-size: 30px;
        margin-top: 0px;
        padding-top: 0px;
        text-align: center;
    }
    .grid-values .descripcion p{
        font-size: 25px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 0px;
        width: 95%;
        margin: 0 auto;
    }
    .vg1, .vg2, .vg3, .vg4, .vg5{
        display: block;
        margin-bottom: 40px;
    }

}
