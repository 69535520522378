.contain-jobs{
    width: 100%;
    height: 100vh;
}
.slide-job{
    width: 100%;
    margin: 0 auto;
    height: 150PX;
    background-image: url("../../images/823037.png");
    background-size: cover;
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
    color: white;
    position: relative;
}
.slide-job .capa{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.523);
    z-index: 5;
}

.slide-job h2{
    width: 90%;
    margin: 0 auto;
    font-size: 50px;
    position: relative;
    z-index: 10;
    padding-top: 40px;
}
.content-jobs{
    width: 90%;
    margin: 0 auto;
}
/* cartas de vacantes */
.grid-carts-jobs{
    padding-top: 50px;
    padding-bottom: 20px;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
    grid-gap: 3%;
}


@media screen and (max-width: 480px) {
    .grid-carts-jobs{
        grid-template-columns: 100%;
        grid-template-areas: "a""b""c"
    } 
    .slide-job h2{
        font-size: 2.3rem;
        position: relative;
        z-index: 10;
        padding-top: 40px;
    }
}

.cart-item{
    border: 1px solid gray;
    border-radius: 10px;
    padding: 15px;
    background-image: url("../../images/e1b17162777475dad99d27186fa2453d.png");
    color: white;
    position: relative;
    overflow: hidden;
    height: auto;
    padding-bottom: 80px;
}
.loading-carts-jobs{
    height: auto;
    text-align: center;
    background-color: #ffb8de;
    margin-bottom: -50px;
    position: relative;
}
.loading-carts-jobs img{
    width: 30%;
}
.loading-carts-jobs h2{
    position: absolute;
    z-index: 10;
    font-size: 50px;
    color: white;
    margin-top: 20px;
    top: 0;
    text-align: center;
    width: 100%;
}

.cart-item-cap{
    background-color: rgba(0, 0, 0, 0.74);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 10px;
    z-index: 5;
}
.desc-item-cart{
    position: relative;
    z-index: 10;
    height: auto;
}

.cart-item h2{
    font-size: 1.1rem;
    text-align: justify;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.cart-item .item-depart{
    margin-top: 0px;
    padding-top: 0px;
    color: rgb(239, 73, 73);
    font-size: 18px;   
    font-weight: bold;
}

.descrip p{
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    text-align: justify;
    height: auto;
    margin-bottom: 50px;
}
.button-post{
    margin: 0 auto;
    text-align: center;
    position: absolute;
    right: 0;    
    bottom: 0;
    z-index: 10;
    margin-bottom: 20px;
    margin-right: 20px;
}
.button-post button{
    color: white;
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    height: 40px;
    width: auto;
    /* background-color: rgb(216, 62, 62); */
    background-color: transparent;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
    border:1px solid white;
}
.button-post button:hover{
    background-color: rgb(192, 57, 57);
}
/* descripcion de puesto y formulario */
.content-descrip-jobitem{
    width: 100%;
    padding-top: 30px;
    background-image: url("https://i.ytimg.com/vi/rp4Z2KQ0UyI/maxresdefault.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0%;
    padding-bottom: 20px;
}
.grid-descrip-jobs{
    display: grid;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    grid-gap: 10px;
    height: auto;
}
.desc-job1{
    font-size: 16px;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.867);
    padding: 15px;
    border: none;
    border-radius: 15px;
    color: black;
    grid-area: a;
}
.desc-job1 span{
    font-weight: bold;
}
.desc-job2{
    grid-area: b;
}


.jobs-req-item .title-items-list{
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.item-desc-info{
    text-align: justify;
    padding-left: 15px;
}
.jobs-req-item .list-items{
    padding-left: 15px;
    padding-top: 0px;
    margin-top: 0px;
}

.form-content-user{
    background-color: white;
    border-radius: 15px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: center;
}
.form-content{
    width: 90%;
    margin: 0 auto;
}
.options-user{
    text-align: center;
}
.frico-icon-logo{
    width: 30%;
}
.crud-user-info{
    width: 80%;
    margin: 0 auto;
}
.options-user{
    width: 100%;
}
.options-user h2{
    text-align: center;
    font-size: 30px;
    margin-top: 0px;
    padding-top: 0px;
}
.options-user .consult-vac p{
    text-align: justify;
    font-size: 18px;
}
.options-user .register-vacante{
    text-align: center;
}
.options-user .register-vacante button{
    background-color: rgb(92, 27, 27);
    border: none;
    width: auto;    
    border-radius: 15px;
    height: 50px;
    color: white;
    font-size: 20px;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}
/* form inputs */
.grid-labor input{
    font-size: 20px;
    padding-bottom: 10px;
}
.button-solict{
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-areas: "a b";
    grid-gap: 4%;
    text-align: center;
    margin-left: 10px;
}

.button-solict button{
    background-color: rgb(92, 27, 27);;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    width: 100%;
    height: 50px;
}

.success-application{
    width: 95%;
    margin: 0 auto;
}
.success-application p{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    text-align: justify;
    padding-top: 15px;
}
.success-application .folio-resp{
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
    font-size: 40px;
}
.grid-forms-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-areas: "a b c";
    grid-gap: 4%;
}
.load-sent-applicants-gif{
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.load-sent-applicants-gif img{
    width: 70%;
}
.load-sent-applicants-gif p{
    font-size: 2.6rem;
    color: white;
    position: absolute;
    bottom: 0; 
    text-align: center;
    width: 100%;
    font-weight: bold;
}
.back-form-labor_change{
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
}
.back-form-labor_change .button-solict{
    background-color: rgb(92, 27, 27);;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    width: 50%;
    height: 50px;
}
.button-solict-2{
    background-color: rgb(92, 27, 27);;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    width: 50%;
    height: 50px;
}

.thanks-vacants{
    color: gray;
    width: 100%;
    grid-area: b;
}
.thanks-vacants h2{
    text-align: center;
    font-size: 30px;
    margin-top: 0px;
    padding-top: 0px;
}


@media screen and (max-width: 480px) {
    .grid-descrip-jobs {
        width: 95%;
        grid-template-columns: 100%;
        grid-template-areas: "a""b";
    }
    .desc-job1{
        width: 92%;  
    }
    .loading-carts-jobs img{
        width: 80%;
    }
    .loading-carts-jobs{
        height: 50vh;
    }
}