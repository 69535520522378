.section-container{
   height: 100vh; 
   background: #fff fixed no-repeat 50% 50%;
   background-size: cover;
   position: relative;
   overflow: hidden;
}
#section-container{
    background-image: url("../../images/1710973750630.png") !important;
}

.section-content{
    background-color: rgba(255, 255, 255, 0.899);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
}
.section-info{
    width: 70%;
    text-align: center;
    margin: 0 auto;
}
.section-info .text-gray{
    font-weight: bold;
    color: rgb(131, 131, 131);
    font-size: 30px;
    margin-bottom: 10px;
}
.section-info .text-title{
    font-size: 50px;
    font-weight: bold;
    color: black;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.section-info .text-about{
    font-size: 30px;
    text-align: justify;
    margin-top: 10px;
}


.section-grid{
    text-align: center;
}
.section-grid img{
    width: 40%;
}

@media (max-width: 480px){

    .section-info .text-title{
        font-size: 30px;
    }
    .section-info .text-about{
        font-size: 25px;
    }

    .section-container{
        height: 90vh; 
    }
    .section-grid img{
        width: 100%;
    }
}