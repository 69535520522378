.territory_container{
    width: 100%;

}
.territory-content{
    width: 95%;
    margin: 0 auto;
    text-align: center;
}

.territory-grid{
    display: grid;
    grid-template-columns: 30% 65%;
    margin: 0 auto;
    width: 100%;
    grid-template-areas: "a b";
    grid-gap: 5%;
    padding-bottom: 50px;
}
.tg1 .section-info{
    width: 100%;    
}
.tg1 .section-info .title{
    font-size: 30px;
    text-align: left;
    margin-top: 0px;
    width: 100%;
    margin-bottom: 0px;
}
.tg1 .section-info p{
    width: 100%;
    font-size: 18px;
    text-align: justify;
}
.tg1 .section-info ul{
    list-style: decimal;
    text-align: left;
}
.tg1 .section-info li{
    font-size: 18px;
    padding-bottom: 5px;
}

@media (max-width: 480px){
    .territory-grid{
        grid-template-columns: 100%;
        grid-template-areas: "a""b";
        grid-gap: 0%;
        padding-bottom: 20px;
    }
    .tg1 .section-info .title{
        text-align: center;
    }
}