.forms-inputs input{
    font-size: 22px !important;
    margin-bottom: 40px;
    padding: 5px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.forms-inputs textarea{
    font-size: 23px;
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    height: 70px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.order-content{
    padding-bottom: 50px;
}
.order-content .forms{
    width: 90%;
}

.order-content .forms h2{
    font-size: 25px;
    margin-top: 0px;
}

.order-content button{
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    background-color: rgb(169, 2, 2);
    color: white;
}


@media (max-width: 480px){

    .custom-modal{
        min-width: 90% !important;
        width: 90% !important;
    }
    .order-content .forms h2{
        font-size: 20px;
    }

    .forms-inputs input{
        font-size: 20px !important;
    }
    .forms-inputs textarea{
        font-size: 21px;
        height: 70px;
    }
}
