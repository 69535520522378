.frezz-container{
    width: 100%;
}
.frezz-content{
    width: 70%;
    margin: 0 auto;
    text-align: center;
}
.frezz-content h2{
    font-size: 30px;
    text-align: center;
}

.frezz-forms{
    width: 100%;
    padding-bottom: 50px;
}
.frezz-forms input{
    font-size: 22px !important;
    margin-bottom: 30px;
    padding: 5px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
}
.frezz-forms textarea{
    font-size: 23px;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    height: 70px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.frezz-content button{
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    background-color: rgb(169, 2, 2);
    color: white;
}

@media (max-width: 480px){
    .frezz-content h2{
        font-size: 25px;
    }
    
    .frezz-forms input{
        font-size: 20px !important;
    }
    .frezz-forms textarea{
        font-size: 21px;
    }
    
     
}