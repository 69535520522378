.contact-container{
    height: auto;
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}
#contact-container{
    background-image: url("https://media.istockphoto.com/id/1161805849/es/foto/fresa-vainilla-helado-de-chocolate-con-cono-de-gofre-sobre-fondos-de-piedra-de-m%C3%A1rmol.jpg?s=612x612&w=0&k=20&c=z_4LjOu3aqqSXHKeFmG1HnQR5yUCREBq0KQmLErQdn4=");
}
.contact-grid{
    display: grid;
    grid-template-columns: 30% 64%;
    grid-gap: 6%;
    grid-template-areas: "a b";
    margin: 0 auto;
    width: 90%;
    padding-top: 40px;
}
.contg1{
    width: 100%;
    background-color: white;
    height: auto;
    padding-bottom: 20px;
}
.contg1 .address{
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
}


.contg1 .address h2{
    font-size: 25px;
    margin-bottom: 0px;
}
.contg1 .address p{
    font-size: 20px;
    text-align: left;
    margin-top: 0px;
}

.contg1{grid-area: a;}
.contg2{grid-area: b;}

.contg2{
    width: 100%;
    height: auto;
    background-color: white;
}
.gmaps{
    width: 100%;
    text-align: center;
}
.gmaps iframe{
    width: 90%;
    height: 100%;
}
.forms{
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.forms h2{
    font-size: 40px;
    text-align: center;
    font-weight: bold;
}

.forms input{
    width: 80%;
    font-size: 25px;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 25px;
    outline: none;
}

.forms .submit{
    text-align: center;
    padding-top: 30px;
}
.forms .submit button{
    width: 80%;
    height: 60px;
    font-size: 20px;
    border-radius: 15px;
    border: none;
    font-weight: bold;
    cursor: pointer;
}

@media (max-width: 480px){
    .contact-grid{
        grid-template-columns: 100%;
        grid-gap: 3%;
        grid-template-areas: "b""a";
        padding-top: 40px;

    }
    .forms h2{
        font-size: 30px;
    }
    .forms input{
        font-size: 22px;
        margin-bottom: 25px;
        outline: none;
    }
    
    .forms .submit{
        text-align: center;
        padding-top: 30px;
    }
    .forms .submit button{
        width: 80%;
        height: 60px;
        font-size: 20px;
        border-radius: 15px;
        border: none;
        font-weight: bold;
        cursor: pointer;
    }
    
    .contg1 .address h2{
        font-size: 20px;
        margin-bottom: 0px;
    }
    .contg1 .address p{
        font-size: 18px;
        text-align: left;
        margin-top: 0px;
    }
    .contg2{
        padding-bottom: 30px;
    }

}