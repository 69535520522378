.footer-container{
    height: auto; 
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
#footer-container{
    background-image: url("../../images/helado-bombon-2353891.webp");
}


.cap-black{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.81);
}

.footer-content{
    width: 90%;
    margin: 0 auto;
    z-index: 10;
    position: relative;
    color: white;
}
.footer-grid{
    display: grid;
    grid-template-columns: 20% 28% 28% 20%;
    grid-template-areas: "a b c d";
    width: 100%;
    padding-top: 20px;
    margin: 0 auto;
}

.ffg1{grid-area: a;}
.ffg2{grid-area: b;}
.ffg3{grid-area: c;}
.ffg4{grid-area: d;}

.footer-grid .ffg1{
    padding-top: 0px;
    margin-top: 30px;
}
.footer-grid .ffg1 img{
    width: 100%;
}

.footer-grid .line{
    height: 1px;
    background-color: red;
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.footer-grid .ffg2, .footer-grid .ffg3, .footer-grid .ffg4{
    width: 90%;
    margin: 0 auto;
}
.footer-grid .ffg2 h2, .footer-grid .ffg3 h2, .footer-grid .ffg4 h2{
    text-align: center;
}
.footer-grid .ffg2 p, .footer-grid .ffg3 p, .footer-grid .ffg4 p{
    width: 90%;
    margin: 20px auto;
}


.footer-grid .ffg2, .footer-grid .ffg3{
    border-right: 1px solid white;
    padding-right: 20px;
}



.footer-grid p{
    font-size: 18px;
}

.footer-page{
    background-color: black;
    height: 50px;
    display: flex;
}
.footer-page{
    color: white;
    font-size: 18px;
    overflow: hidden;
    padding-left: 10px;
}
.footer-page p{
    padding-top: 10px;
    margin-top: 0px;
}
.footer-page span{
    color: red;
}
.footer-page .terms{
    text-align: center;
    padding-left: 200px;
}
.footer-page .terms:hover{
    cursor: pointer;
    color: red;
}

@media (max-width: 480px){
    .footer-grid{
        grid-template-columns: 100%;
        grid-template-areas: "a""b""c""d";
        padding-top: 20px;
        margin: 0 auto;
    }
    .footer-grid .ffg2, .footer-grid .ffg3{
        border-right: none;
        padding-right: 0px;
    }
    .footer-grid .ffg1{
        width: 100%;
        text-align: center;
    }
    .footer-grid .ffg1 img{
        width: 80%;
        padding: 0px;
    }
    .footer-page{
        height: auto;
        display: block;
    }
    .footer-page{
        padding-left: 0px;
    }
    .footer-page p{
        padding-top: 10px;
        margin-top: 0px;
        width: 95%;
        text-align: left;
        margin: 0 auto;
    }
    .footer-page .terms{
        text-align: left;
        padding-left: 0px;
        padding-bottom: 30px;
        text-decoration: underline;
    }

    
}