@font-face {
    font-family: 'Bauhaus Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/BauhausBold.woff') format('woff');
}
    

.slide-container{
    width: 100%;
}
.slide-content{
    background-color: #e4292a;
    background-image: url("../../images/fondoasd.png");
    background-size: cover;
    height: 80vh;
}
.slide-section{
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";
}
.slide-images{
    text-align: center;
}
.slide-titles{
    width: 80%;
    text-align: center;
    margin: 0 auto;
}
.slide-titles h2{
    font-size: 80px;
    color: white;
    text-align: left;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    /* font-family:'Bauhaus Bold'; */
    margin-bottom: 0px;
}
.slide-titles p{
    font-size: 25px;
    color: white;
    text-align: justify;
}

.slide-images img{
    width: 80%;
    position: relative;
    z-index: 15 !important;

}
.slide-black-container{
    height: auto;
    margin-top: 0px;
    /* background-color: black; */
    background-image: url("../../images/823037.png");
    background-size: cover;
    background-position-y: 90%;
    position: relative;
    padding-bottom: 25px;
}
.cap-black2{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.467);   
}
.slide-black-grid{
    display: grid;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: 30% 33% 30%;
    grid-template-areas: "a b c";
    grid-gap: 20px;
    padding-top: 25px;
    position: relative;
    z-index: 10;
}
.sbg1, .sbg2, .sbg3{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    padding: 10px;
    grid-gap: 10px;
}


.sbg1 img, .sbg2 img, .sbg3 img{
    width: 70%;
    padding-top: 20px;
    text-align: center;
}
.sbgtext .title{
    font-size: 20px;
    text-align: left;
    font-weight: bold;
    color: white;
    margin-bottom: 0px;
    margin-top: 5px;
}
.sbgtext .text{
    margin-top: 0px;
    font-size: 18px;
    text-align: left;
    color: rgb(204, 204, 204);
    text-align: justify;
    height: auto;
    padding-bottom: 50px;
}
.sbgtext{
    text-align: center;
    height: auto;
    position: relative;
}


.slide-black-grid button{
    width: 80%;
    font-size: 20px;
    font-weight: bold;
    background-color: #2c2c2c;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 0 auto;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    border: 2px solid rgb(132, 132, 132);
    position: absolute;
    bottom: 0;
    left: 0;
}


.services-container{
    height: 50vh;
}
.services-grid{
    display: grid;
    width: 90%;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
    grid-gap: 5%;
    margin: 0 auto;
    padding-top: 20px;
}
.services-grid .serg1{
    grid-area: a;
}
.services-grid .serg2{
    grid-area: b;
}
.services-grid .serg3{
    grid-area: c;
}
.services-grid{
    color: white;
}

.services-grid .cart{
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "a""b";
    padding-top: 30px;
}
.services-grid .text-info{
    background-image: url("../../images/fondo-congelador.jpg");
    text-align: center;
    height: 200px;
    background-size: cover;
    background-position-y: 100%;
}
.services-grid .actions-cart{
    border-left: 1px solid gray;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
    height: 50px;
    text-align: center;
    padding-top: 20px;
}
.services-grid .actions-cart button{
    color: white;
    width: 40%;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(201, 34, 34);
    border: none;
    border-radius: 5px;
}

.custom-modal{
    width: 60% !important;
    min-width: 60% !important;
}



@media (max-width: 480px){

    .slide-titles h2{
        font-size: 35px;
        text-align: center;
    }

    .slide-section{
        grid-template-columns: 100%;
        grid-template-areas: "a""b";
    }
    .slide-titles p{
        font-size: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .slide-images img{
        width: 60%;
        margin-top: 0px;
        padding-top: 0px;
    } 
    .slide-content{
        height: auto;
    }
    .slide-black-grid{
        width: 90%;
        grid-template-columns: 100%;
        grid-template-areas: "a""b""c";
        grid-gap: 20px;
        padding-top: 25px;
        position: relative;
        z-index: 10;
    }
}
