.navbar-container{
    margin: 0 auto;
    width: 100%;
    height: 100px;
    overflow: hidden;
    background-color: #e93537;
}
.navbar-ul{
    display: "";
}

.navbar-content{
    width: 95%;
    margin: 0 auto;
    position: relative;
}
.navbar-grid{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    position: relative;
}
.navbar-secction-icons{
    grid-area: a;
    height: 110px;
}
.navbar-secction-icons img{
    height: 100%;
}
.navbar-menu-items{
    grid-area: b;
}
.navbar-ul{
    list-style: none;
    display: flex;
}
.navbar-ul li{
    color: white;
    font-size: 18px;
    width: 150px;
    font-weight: bold;
    padding-top: 25px;
    text-align: right;
}
.aviso-privacidad-navbar{
    width: 250px !important;
    text-align: right;
}
.bolsa-trabajo-navbar{
    width: 220px !important;
    text-align: right;
}

.navbar-ul li:hover{
    cursor: pointer;
}

.menu-items-sections{
    position: absolute;
    width: 100%;
    height: 100%;   
    background-image: url("../../images/823037.png");
    z-index: 50;
}
.navbar-ul-phone{
    list-style: none;
    color: white;
    font-size: 25px;
    z-index: 10;
    position: relative;
    padding-top: 20px;
}
.navbar-ul-phone li{
    padding-bottom: 40px;
}
.navbar-menu-items svg{
    display: none;
}


@media (max-width: 480px){
    .navbar-ul{
        display: none;
    }
    .navbar-menu-items svg{
        color: white;
        font-size: 40px;
        padding-top: 25px;
        display: block;
    }
    .navbar-grid{
        grid-template-columns: 80% 20%;
    }
    .navbar-secction-icons img{
        height: 80%;
    }
}