.mision-container{
    height: auto; 
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}
#mision-container{
    background-image: url("../../images/chocolate.png") !important;
}
.grid-mision{
    width: 90%;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";
    margin: 0 auto;
    padding-top: 20px;
}
.grid-mision .mision-desc h2{
    color: white;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
}
.grid-mision .mision-desc p{
    font-size: 30px;
    color: white;
    text-align: justify;
}
.mision-image{
    text-align: center;
}
.mision-image img{
    width: 80%;
    padding-top: 20px;
}


@media (max-width: 480px){
    .grid-mision{
        grid-template-columns: 100%;
        grid-template-areas: "a""b";
    }
    .grid-mision .mision-desc h2{
        font-size: 40px;
    }
    .grid-mision .mision-desc p{
        font-size: 25px;
    }
}