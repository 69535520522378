.grid-folio-consult-status{
    width: 70%;
    margin: 0 auto;
}
.button-solict-code-applicants{
    margin-top: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-areas: "a b";
    grid-gap: 4%;
    margin-left: 15px;
}
.button-solict-code-applicants button{
    background-color: rgb(92, 27, 27);;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    width: 100%;
    height: 50px;
}
.grid-verification-code{
    width: 80%;
    margin: 0 auto;
}
.grid-verification-code p{
    text-align: justify;
    padding-bottom: 15px;
}
.grid-verification-code div{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}
.info-solicitante{
    width: 80%;
    margin: 0 auto;
}
.info-solicitante p{
    text-align: justify;
    font-size: 18px;
}
.info-solicitante span{
    font-weight: bold;
}

.pdf-editable p{
    width: 90%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    padding-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
}
.pdf-editable span{
    font-weight: bold;
    color: blue;
    cursor: pointer;
}
.custom-modal-pdf{
    min-width: 90% !important;
    width: 90% !important;
    height: 90vh !important;
}
.pdf-create-aspirant{
    width: 95%;
    height: 100%;
    margin: 0 auto;

}
.pdf-create-aspirant iframe{
    width: 100%;
    height: 90vh;
}
.custom-modal-stand{
    min-width: 30%;
}
.tutorial-pdf-edit{
    
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
}
.tutorial-pdf-edit p{
    font-size: 18px;
    text-align: justify;
}
.tutorial-pdf-edit button{
    background-color: rgb(92, 27, 27);;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    width: 80%;
    height: 50px;
}
