.terms-content{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 50px;
}
.terms-content h2{
    text-align: center;
}
.terms-content p{
    text-align: justify;
    font-size: 18px;
}