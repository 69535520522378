.vision-container{
    height: auto; 
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}
#vision-container{
    background-image: url("../../images/823037.png");
    background-position-x: 40%;
}
.cap-black{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.608);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.vision-grid{
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: "a b";
    margin: 0 auto;
    width: 90%;
    position: relative;
    z-index: 10;
}
.visg1{grid-area: a;}
.visg2{grid-area: b;}

.visg2 .description{

}
.visg2 .description h2{
    color: white;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
}
.visg2 .description p{
    font-size: 30px;
    color: white;
    text-align: justify;
}
.visg1{
    text-align: right;
    padding-right: 50px;
}
.visg1 img{
    width: 70%;
    margin: 0 auto;
    padding-top: 50px;
}


@media (max-width: 480px){

    .vision-grid{
        grid-template-columns: 100%;
        grid-template-areas: "b""a";
    }
    .visg2 .description h2{
        font-size: 40px;
    }
    .visg2 .description p{
        font-size: 25px;
        color: white;
        text-align: justify;
    }
}